import React, { useEffect, useState, useCallback } from 'react';
import { Tag, DatePicker, Tooltip } from 'antd4';
import op from 'object-path';
import moment from 'moment';

import './Fields.scss';
import AuditLog from '../../../components/AuditLog';

const DateField = ({
  path,
  label,
  width,
  picker,
  disabled,
  isValid = momentValue => true,
  formatLabel,
  data,
  audit = [],
  updateField,
  showTime
}) => {
  let dataValue = op.get(data, path);
  const [value, setValue] = useState(dataValue);
  const ref = React.createRef();
  const [statusClass, setStatusClass] = useState('');
  const [isFormatError, setFormatError] = useState(false);

  useEffect(() => {
    const updateValue = op.get(data, path);
    setValue(updateValue);
  }, [data, path]);

  useEffect(() => {
    // auto update status class if format error
    if (isFormatError) {
      // console.log('effect changed to save-error');
      setStatusClass('save-error');
      setTimeout(() => {
        setFormatError(false);
      }, 2000);
    } else {
      // console.log('effect reset');
      setStatusClass('');
    }
  }, [isFormatError]);

  const myAudit = React.useMemo(
    () => audit?.filter(item => item?.path === path),
    [audit, path]
  );

  const onSave = useCallback(
    async value => {
      // console.log(value);
      if (isValid(value)) {
        setFormatError(false);
      } else {
        // console.log('invalid format: ', value);
        setFormatError(true);
        return;
      }

      const response = await updateField(path, value);
      if (response?.ok) {
        setStatusClass('save-success');
        setTimeout(() => {
          setStatusClass('');
        }, 3000);
      } else {
        setStatusClass('save-error');
      }
    },
    [isValid, path, updateField]
  );

  return (
    <div
      key={path}
      className={`input-container d-flex items-stretch w-${width ?? '25'}`}
    >
      {!!isFormatError && (
        <Tooltip
          open={isFormatError}
          placement="topLeft"
          title={
            formatLabel ? (
              <span>Formatos suportados: {formatLabel}</span>
            ) : (
              'Formato inválido'
            )
          }
        ></Tooltip>
      )}
      <div className="input-tag-container h-full">
        <Tag className="input-prefix border-radius flex-shrink-0 mr-0">
          {label ?? ''}
          {!disabled && (
            <AuditLog
              audits={myAudit}
              label={<>Histórico{label ? ` - ${label}` : ``}</>}
            ></AuditLog>
          )}
        </Tag>
      </div>
      <DatePicker
        showTime={
          showTime ? { defaultValue: moment('12:00:00', 'HH:mm:ss') } : false
        }
        picker={picker}
        disabled={disabled}
        ref={ref}
        className={'input input-date ' + statusClass}
        value={value ? moment(value) : null}
        onChange={onSave}
      />
    </div>
  );
};

export default DateField;
