import React, { useEffect, useState, useCallback } from 'react';
import { Checkbox, Tag } from 'antd4';
import op from 'object-path';

import './Fields.scss';
import AuditLog from '../../../components/AuditLog';

const NumberField = ({
  width,
  label,
  path,
  disabled,
  data,
  audit = [],
  updateField
}) => {
  let dataValue = op.get(data, path);
  const [value, setValue] = useState(dataValue);

  useEffect(
    value => {
      const updateValue = op.get(data, path);
      setValue(updateValue);
    },
    [data, path]
  );

  const myAudit = React.useMemo(
    () => audit?.filter(item => item?.path === path),
    [audit, path]
  );

  const [statusClass, setStatusClass] = useState('');
  const onSave = useCallback(
    async event => {
      const checked = event?.target?.checked;
      const oldValue = value;

      setValue(checked);
      const response = await updateField(path, checked);
      if (response?.ok) {
        setStatusClass('save-success');

        setTimeout(() => {
          setStatusClass('');
        }, 600);
      } else {
        setValue(oldValue);
        setStatusClass('save-error');
      }
    },
    [path, value, updateField]
  );

  return (
    <div
      key={path}
      className={`input-container d-flex align-items-center w-${width ?? '25'}`}
    >
      <div
        className={`w-full status-container px-2 bg-white border-mediumgray rounded-sm justify-between d-flex align-items-center ${statusClass ??
          ''}`}
      >
        <Tag className="input-prefix border-radius flex-shrink-0 mr-0">
          {label ?? ''}
          {!disabled && (
            <AuditLog
              audits={myAudit}
              label={<>Histórico{label ? ` - ${label}` : ``}</>}
            ></AuditLog>
          )}
        </Tag>
        <Checkbox
          className={'px-1 input-checkbox '}
          disabled={disabled}
          checked={value}
          onChange={async event => {
            return await onSave(event);
          }}
        />
      </div>
    </div>
  );
};

export default NumberField;
