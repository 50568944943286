import React from 'react';
import { ArraySection, ArrayTitle, ActionDeleteArrayElement } from '../array';

import { Field, TextAreaField, CheckboxField } from '../../../fields';

import '../../../fields/Fields.scss';

const dataPath = 'notification';
let dataCache; //eslint-disable-line no-unused-vars

const Notifications = ({
  data,
  audit,
  addArrayElement,
  deleteArrayElement,
  updateField
}) => {
  const [filteredData, setFilteredData] = React.useState(data);
  dataCache = data;

  React.useEffect(() => {
    // Reset filters and cache when new data comes in
    setFilteredData(data);
    dataCache = data;
  }, [data]);

  return (
    <ArraySection
      data={filteredData}
      audit={audit}
      dataPath={dataPath}
      deleteArrayElement={deleteArrayElement}
      updateField={updateField}
      renderToolbar={props => {
        return (
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex justify-content-start align-items-center">
              <ArrayTitle title="Avisos"></ArrayTitle>
            </div>
          </div>
        );
      }}
    >
      <SingleNotification></SingleNotification>
    </ArraySection>
  );
};

export default Notifications;

const SingleNotification = ({
  data,
  audit,
  dataIndex,
  updateField,
  deleteArrayElement
}) => {
  const element = data?.[dataPath]?.[dataIndex] ?? {};
  return (
    <div
      key={'' + dataPath + dataIndex}
      className={`fields-container array-item array-item-${
        dataIndex % 2 === 0 ? 'even' : 'odd'
      } w-100 border-radius`}
    >
      <div className="array-index d-flex justify-content-start w-100">
        <span className="d-flex justify-content-center align-items-center border-radius-50 mr-3">
          {dataIndex}
        </span>
        <ActionDeleteArrayElement
          onClick={() => deleteArrayElement(dataPath, element?._id)}
        ></ActionDeleteArrayElement>
      </div>
      <Field
        key={'notificationType'}
        width={50}
        label={'Tipo de Aviso'}
        editable={false}
        path={`notification.${dataIndex}.notificationType`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></Field>
      <CheckboxField
        key={'read'}
        label={'Tratado'}
        path={`notification.${dataIndex}.read`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></CheckboxField>
      <TextAreaField
        key={'message'}
        width={100}
        label={'Mensagem'}
        editable={false}
        minRows={2}
        path={`notification.${dataIndex}.message`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></TextAreaField>
    </div>
  );
};
