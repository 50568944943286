import React from 'react';

import {
  Field,
  DateField,
  TextAreaField,
  CheckboxField,
  NumberField,
  Hr,
  Placeholder
} from '../../../fields';

import '../../../fields/Fields.scss';
import {
  ArraySection,
  ArrayTitle,
  ActionAddArrayElement,
  ActionDeleteArrayElement
} from '../array';

const dataPath = 'irs';
let dataCache; //eslint-disable-line no-unused-vars

const IRS = ({
  data,
  audit,
  addArrayElement,
  deleteArrayElement,
  updateField
}) => {
  const [filteredData, setFilteredData] = React.useState(data);
  dataCache = data;

  React.useEffect(() => {
    // Reset filters and cache when new data comes in
    setFilteredData(data);
    dataCache = data;
  }, [data]);

  return (
    <ArraySection
      data={filteredData}
      audit={audit}
      dataPath={dataPath}
      deleteArrayElement={deleteArrayElement}
      updateField={updateField}
      renderToolbar={props => {
        return (
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex justify-content-start align-items-center">
              <ArrayTitle title="IRS"></ArrayTitle>
            </div>
            <div className="d-flex justify-content-end align-items-center fg-1">
              <ActionAddArrayElement
                onClick={() => addArrayElement(dataPath)}
              ></ActionAddArrayElement>
            </div>
          </div>
        );
      }}
    >
      <SingleIRS></SingleIRS>
    </ArraySection>
  );
};

export default IRS;

const SingleIRS = ({
  data,
  audit,
  dataIndex,
  updateField,
  deleteArrayElement
}) => {
  const element = data?.[dataPath]?.[dataIndex] ?? {};
  return (
    <div
      key={'' + dataPath + dataIndex}
      className={`fields-container array-item array-item-${
        dataIndex % 2 === 0 ? 'even' : 'odd'
      } w-100 border-radius`}
    >
      <div className="array-index d-flex justify-content-start w-100">
        <span className="d-flex justify-content-center align-items-center border-radius-50 mr-3">
          {dataIndex}
        </span>
        <ActionDeleteArrayElement
          onClick={() => deleteArrayElement(dataPath, element?._id)}
        ></ActionDeleteArrayElement>
      </div>
      <DateField
        showTime={false}
        key={'year'}
        picker={'year'}
        label={'Ano'}
        width={33}
        path={`irs.${dataIndex}.year`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></DateField>
      <TextAreaField
        key={'observation_1'}
        width={100}
        label={'Observação'}
        minRows={2}
        path={`irs.${dataIndex}.observation_1`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></TextAreaField>
      <Field
        key={'present_situation'}
        width={100}
        label={'Situação Actual'}
        path={`irs.${dataIndex}.present_situation`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></Field>
      <NumberField
        key={'gross_amount'}
        label={'Valor Ilíquido'}
        width={33}
        path={`irs.${dataIndex}.gross_amount`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></NumberField>
      <Placeholder width={66}></Placeholder>
      <NumberField
        key={'tax_withholding'}
        label={'Retenções na Fonte'}
        path={`irs.${dataIndex}.tax_withholding`}
        data={data}
        audit={audit}
        width={33}
        updateField={updateField}
      ></NumberField>
      <CheckboxField
        key={'has_anual_declaration'}
        label={'Declaração Anual'}
        width={33}
        path={`irs.${dataIndex}.has_anual_declaration`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></CheckboxField>
      <Placeholder width={33}></Placeholder>
      <NumberField
        key={'conservation_maintenance'}
        label={'Conservação e Manutenção'}
        path={`irs.${dataIndex}.conservation_maintenance`}
        data={data}
        audit={audit}
        width={33}
        updateField={updateField}
      ></NumberField>
      <CheckboxField
        key={'has_conservation_proof'}
        label={'Comprovativo'}
        width={33}
        path={`irs.${dataIndex}.has_conservation_proof`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></CheckboxField>
      <Placeholder width={33}></Placeholder>
      <NumberField
        key={'condominium'}
        label={'Condomínio'}
        path={`irs.${dataIndex}.condominium`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></NumberField>
      <NumberField
        key={'imi'}
        label={'IMI'}
        path={`irs.${dataIndex}.imi`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></NumberField>
      <NumberField
        key={'is'}
        label={'IS'}
        path={`irs.${dataIndex}.is`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></NumberField>
      <Placeholder width={25}></Placeholder>
      <NumberField
        key={'other'}
        label={'Outro'}
        path={`irs.${dataIndex}.other`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></NumberField>
      <Field
        key={'other_observations'}
        width={75}
        label={'Obs.'}
        path={`irs.${dataIndex}.other_observations`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></Field>
      <Placeholder width={100}></Placeholder>
      <Hr width={100}></Hr>
      <Placeholder width={100}></Placeholder>
      <DateField
        showTime={false}
        key={'purchase_year'}
        label={'Ano da Compra'}
        picker={'year'}
        path={`irs.${dataIndex}.purchase_date`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></DateField>
      <DateField
        showTime={false}
        key={'purchase_month'}
        label={'Mês da Compra'}
        picker={'month'}
        path={`irs.${dataIndex}.purchase_date`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></DateField>
      <NumberField
        key={'purchase_value'}
        label={'Valor da Compra'}
        path={`irs.${dataIndex}.purchase_value`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></NumberField>
      <Placeholder width={25}></Placeholder>
      <DateField
        showTime={false}
        key={'sell_year'}
        label={'Ano da Venda'}
        picker={'year'}
        path={`irs.${dataIndex}.sell_date`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></DateField>
      <DateField
        showTime={false}
        key={'sell_month'}
        label={'Mês da Venda'}
        picker={'month'}
        path={`irs.${dataIndex}.sell_date`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></DateField>
      <NumberField
        key={'sell_value'}
        label={'Valor da Venda'}
        path={`irs.${dataIndex}.sell_value`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></NumberField>
      <Placeholder width={25}></Placeholder>
      <NumberField
        key={'imt_expense'}
        label={'Despesa de IMT'}
        path={`irs.${dataIndex}.imt_expense`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></NumberField>
      <NumberField
        key={'is_expense'}
        label={'Despesa de IS'}
        path={`irs.${dataIndex}.is_expense`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></NumberField>
      <NumberField
        key={'immediacy_expense'}
        label={'Despesa de Imediação'}
        path={`irs.${dataIndex}.immediacy_expense`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></NumberField>
      <Placeholder width={100}></Placeholder>
      <Hr width={100}></Hr>
      <Placeholder width={100}></Placeholder>
      <Field
        key={'irs_submitted'}
        label={'IRS Submetido'}
        path={`irs.${dataIndex}.irs_submitted`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></Field>
      <DateField
        showTime={false}
        key={'submission_date'}
        label={'Data da Submissão'}
        path={`irs.${dataIndex}.submission_date`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></DateField>
      <Field
        key={'submitter'}
        width={50}
        label={'Responsável'}
        path={`irs.${dataIndex}.submitter`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></Field>
      <Placeholder width={100}></Placeholder>
      <Hr width={100}></Hr>
      <Placeholder width={100}></Placeholder>
      <DateField
        showTime={false}
        key={'correction_date'}
        label={'Data de Correcção'}
        path={`irs.${dataIndex}.correction_date`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></DateField>
      <Field
        key={'correction_submitter'}
        width={75}
        label={'Responsável'}
        path={`irs.${dataIndex}.correction_submitter`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></Field>
      <TextAreaField
        key={'observation_2'}
        width={100}
        label={'Observação'}
        minRows={2}
        path={`irs.${dataIndex}.observation_2`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></TextAreaField>
    </div>
  );
};
