import React, { useEffect, useState, useCallback } from 'react';
import { Checkbox, Tag, Tooltip } from 'antd4';
import op from 'object-path';

import './Fields.scss';
import AppToaster from '../components/AppToaster';
import AuditLog from '../../../components/AuditLog';

const WrongPasswordInfo = ({
  width,
  label,
  path,
  disabled,
  data,
  audit = [],
  updateField
}) => {
  let dataValue = op.get(data, path);
  const [value, setValue] = useState(dataValue);

  useEffect(
    value => {
      const updateValue = op.get(data, path);
      setValue(updateValue);
    },
    [data, path]
  );

  const myAudit = React.useMemo(
    () => audit?.filter(item => item?.path === path),
    [audit, path]
  );

  const [statusClass, setStatusClass] = useState('');
  const onSave = useCallback(
    async event => {
      const checked = event?.target?.checked;
      const oldValue = value;

      setValue(checked);
      const response = await updateField(path, checked);
      if (response?.ok) {
        setStatusClass('save-success');

        setTimeout(() => {
          setStatusClass('');
        }, 600);
      } else {
        setValue(oldValue);
        setStatusClass('save-error');
      }
    },
    [path, value, updateField]
  );

  const tooltipTitle = value
    ? 'A última verificação na AT detectou que a password da AT está errada. Deve corrigir a mesma e desmarcar esta opção, para que este cliente volte a ser verificado.'
    : 'Sem problemas detetados na última verificação na AT.';

  return (
    <div
      key={path}
      className={`input-container d-flex align-items-center w-${width ?? '25'}`}
    >
      <Tooltip title={tooltipTitle}>
        <div
          className={`w-full status-container px-2 bg-white ${
            value ? 'save-error' : ''
          } border-mediumgray justify-between d-flex align-items-center ${statusClass ??
            ''}`}
        >
          <div className="">
            <Tag className="input-prefix border-radius ">
              {label ?? ''}
              {!disabled && (
                <AuditLog
                  audits={myAudit}
                  label={<>Histórico{label ? ` - ${label}` : ``}</>}
                ></AuditLog>
              )}
            </Tag>
          </div>

          <Checkbox
            className={'px-1 input-checkbox '}
            disabled={!value}
            checked={value}
            onChange={async event => {
              AppToaster.info({
                message:
                  'O cliente será verificado novamente na próxima consulta à AT.',
                timeout: 3
              });
              return await onSave(event);
            }}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default WrongPasswordInfo;
