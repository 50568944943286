import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Button, Tag, Tooltip, Popconfirm, Spin, notification } from 'antd4';
import {
  LeftCircleOutlined,
  ClusterOutlined,
  IdcardOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  EuroCircleFilled
} from '@ant-design/icons';

import './FamilyTree.scss';
import SingleClient from './SingleClient';
import CreateRelative from './CreateRelative';
import AppToaster from './AppToaster';
import routes from '../network/immigration';
import { set } from 'object-path';

const { getClient, getAudit } = routes;

const FamilyTree = ({
  parentId,
  descendantId,
  match,
  location,
  history,
  tab = 1,
  onCreateRelative = () => {},
  onDelete = () => {},
  search = () => {},
  updateField = () => {},
  addArrayElement = () => {},
  deleteArrayElement = () => {}
}) => {
  const [loading, setLoading] = useState(false);
  const [audit, setAudit] = useState(null);
  const [showCreateRelative, setShowCreateRelative] = useState(false);
  const [parent, setParent] = useState(null);
  const [selected, setSelected] = useState(parent);
  const [type, setType] = useState(SingleClient.TYPES.parent);

  const fetchData = async (options = { allowLoading: true }) => {
    setLoading(options?.allowLoading ?? true);
    let response = await getClient(parentId);

    if (!response?.ok) {
      AppToaster.error({ message: 'Erro a carregar dados', timeout: 1 });
      setParent({});
    } else {
      const data = response?.data ?? {};
      setParent(data);
    }
    setLoading(false);
  };

  const fetchDataWrapper = () => {
    fetchData();
  };

  const refreshUI = () => {
    setAudit(null);
    if (descendantId) {
      setSelected(parent?.family?.find?.(x => x._id === descendantId));
      console.log('Getting audit for descendant: ', descendantId);
      getAudit(descendantId, 'relative').then(response => {
        if (response?.ok) {
          setAudit(response?.data ?? []);

          return;
        }
        notification.error({
          message: 'Erro a carregar registo histórico de dados'
        });
      });
      setType(SingleClient.TYPES.descendant);
    } else {
      setSelected(parent);
      console.log('Getting audit for parent: ', parentId);
      getAudit(parentId, 'client').then(response => {
        if (response?.ok) {
          setAudit(response?.data ?? []);
          return;
        }
        notification.error('Erro a carregar registo histórico de dados');
      });
      setType(SingleClient.TYPES.parent);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refreshUI, []);
  useEffect(refreshUI, [parent, parentId, descendantId, selected]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchDataWrapper, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchDataWrapper, [parentId]);

  const navigateToDescendant = _id => {
    let result;
    const url = location?.pathname.replace(/\/$/, '');
    const descendantId = match?.params?.descendant;
    if (descendantId) {
      result = url.replace(descendantId, _id);
    } else {
      result = url + '/' + _id;
    }

    if (location?.search) {
      result += location.search;
    }
    return history.push(result);
  };

  const localUpdateField = async (path, value, clientId, relativeId = null) => {
    const result = await updateField(path, value, clientId, relativeId);
    if (relativeId) {
      const newRelative = parent?.family?.find(x => x._id === relativeId);
      set(newRelative, path, value);
      setParent({
        ...parent
      });
    } else {
      set(parent, path, value);
      setParent({
        ...parent
      });
    }
    return result;
  };

  const localAddArrayElement = async (
    path,
    clientId,
    relativeId = undefined,
    value = undefined
  ) => {
    const result = await addArrayElement(path, clientId, relativeId, value);
    await fetchData({ allowLoading: false });
    return result;
  };

  const localDeleteArrayElement = async (
    path,
    clientId,
    relativeId = undefined,
    value = undefined
  ) => {
    const result = await deleteArrayElement(path, clientId, relativeId, value);
    await fetchData({ allowLoading: false });

    return result;
  };

  const goBackHome = () => {
    return history.push('/id');
  };

  const createRelative = async relative => {
    const parentId = parent?._id;
    return await onCreateRelative?.(relative, parentId);
  };

  const family = parent?.family ?? [];
  const hasFamily = family.length > 0;

  return (
    <>
      <Helmet>
        <title>{'Cliente ' + selected?.main?.nome ?? ''}</title>
      </Helmet>
      {loading ? (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <Spin size="large"></Spin>
        </div>
      ) : (
        <div
          id="family-tree"
          className="d-flex flex-col justify-content-start align-items-start w-100 h-100"
        >
          <div className="family-header d-flex flex-row justify-content-start align-items-center w-100">
            <Link
              onClick={() => search('')}
              className="row-link h-100 border-radius hover-bg-blue d-flex align-items-center"
              to={type === SingleClient.TYPES.parent ? '../..' : '../..'}
            >
              <LeftCircleOutlined className="icon-back"></LeftCircleOutlined>
            </Link>
            <h3 className="no-margin d-flex justify-content-start">
              <span className="header-title d-flex align-items-center">
                Investidor Principal
              </span>
              <Tag>{parent?.main?.nome ?? ' não encontrado'}</Tag>
            </h3>
          </div>
          <div className="d-flex flex-row justify-content-center align-items-center w-100 h-85">
            <div className="sidebar card h-100 w-20">
              <div className="card-header">
                <h3 className="card-header-heading">
                  <ClusterOutlined className="color-gray header-icon"></ClusterOutlined>
                  Gestão
                </h3>
              </div>
              <div className="card-body d-flex flex-col justify-content-start align-items-start">
                <div className="main-investor w-100 d-flex flex-col justify-content-start align-items-start">
                  <h4 className="card-header-heading color-gray">
                    Investidor Principal
                  </h4>
                  <Tooltip title={'Seleccionar investidor'}>
                    <Button
                      type="secondary"
                      onClick={() => navigateToDescendant('')}
                      className={`border-radius d-flex justify-content-start`}
                    >
                      {parent?.main?.nome ?? ' não encontrado'}
                    </Button>
                  </Tooltip>
                </div>
                <div className="family w-100 d-flex flex-col justify-content-start align-items-start">
                  <h4 className="card-header-heading color-gray">Familiares</h4>
                  {family.map(member => {
                    return (
                      <Tooltip key={member._id} title={'Seleccionar familiar'}>
                        <Button
                          className={`member-button w-100 border-radius d-flex flex-row align-items-center justify-content-between ${
                            descendantId === member._id ? 'focused' : ''
                          }`}
                          onClick={() => navigateToDescendant(member._id)}
                        >
                          <div>
                            {member.main?.nome ? member.main?.nome : 'no name'}
                          </div>
                          {member.main?.familiar_com_faturacao && (
                            <Tooltip
                              onMouseOver={e => e.stopPropagation()}
                              title={'Tem Facturação Própria'}
                            >
                              <EuroCircleFilled className="color-green" />
                            </Tooltip>
                          )}
                        </Button>
                      </Tooltip>
                    );
                  })}
                  <div
                    className={`w-100 d-flex justify-content-${
                      hasFamily ? 'center' : 'start'
                    } align-items-center`}
                  >
                    <Tooltip title={'Criar familiar'}>
                      <Button
                        size="small"
                        type="primary"
                        ghost
                        icon={<PlusOutlined></PlusOutlined>}
                        shape="circle"
                        onClick={() => {
                          setShowCreateRelative(true);
                        }}
                      ></Button>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {/* <div className="card-footer"></div> */}
            </div>

            <div className="data card h-100 w-100">
              <div className="card-header d-flex justify-content-between">
                <h3 className="no-margin">
                  <IdcardOutlined className="color-gray header-icon"></IdcardOutlined>
                  {showCreateRelative
                    ? 'Criar Familiar'
                    : selected?.main?.nome ?? ''}
                </h3>
                <div className="operations d-flex flex-col justify-content-start align-items-start">
                  <div className="d-flex flex-col justify-content-start align-items-start">
                    <div className="operation d-flex flex-row align-items-center">
                      <Tooltip
                        title={'Eliminar ' + selected?.main?.nome ?? 'cliente'}
                      >
                        <Popconfirm
                          okText="Apagar"
                          okButtonProps={{ type: 'danger', size: 'small' }}
                          cancelText="Cancelar"
                          title={
                            <>
                              <h3>Confirmar Operação!</h3>
                              {type === SingleClient.TYPES.parent && (
                                <span>
                                  Todos os familiares serão eliminados!
                                </span>
                              )}
                            </>
                          }
                          icon={
                            <ExclamationCircleOutlined
                              style={{ color: 'red' }}
                            />
                          }
                          onConfirm={async () => {
                            if (type === SingleClient.TYPES.parent) {
                              await onDelete(selected?._id);
                              goBackHome();
                            } else {
                              await onDelete(parent?._id, selected?._id);
                              setParent({
                                ...parent,
                                family: parent?.family?.filter(
                                  x => x._id !== selected?._id
                                )
                              });
                              await navigateToDescendant('');
                            }
                          }}
                        >
                          <Button
                            type="danger"
                            ghost
                            icon={<DeleteOutlined></DeleteOutlined>}
                          >
                            Eliminar
                          </Button>
                        </Popconfirm>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {showCreateRelative ? (
                  <div
                    className={`w-100 d-flex flex-col justify-content-center align-items-center`}
                  >
                    <div className="w-100 d-flex justify-content-start pb-3"></div>
                    <CreateRelative
                      onCreate={createRelative}
                      onCancel={() => {
                        setShowCreateRelative(false);
                      }}
                    ></CreateRelative>
                  </div>
                ) : (
                  <SingleClient
                    audit={audit}
                    tab={tab ?? 1}
                    type={type}
                    data={selected}
                    parent={parent}
                    updateField={localUpdateField}
                    addArrayElement={localAddArrayElement}
                    deleteArrayElement={localDeleteArrayElement}
                  ></SingleClient>
                )}
              </div>
              {/* <div className="card-footer"></div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(FamilyTree);
