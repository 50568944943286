import { ClockCircleOutlined } from '@ant-design/icons';
import { Popover, Spin } from 'antd4';
import moment from 'moment';

const AuditLog = ({ audits, label = 'Histórico' }) => {
  return (
    <Popover
      className="mr-0 max-h-60 overflow-y-scroll  flex-shrink-0"
      placement="right"
      content={
        !audits ? (
          <div className="w-full flex items-center justify-center text-gray-400">
            <Spin className="mr-2"></Spin> A carregar
          </div>
        ) : !audits?.length ? (
          <div className="w-full flex items-center justify-center text-gray-400">
            Sem Alterações
          </div>
        ) : (
          <div className="flex flex-col p-0 border border-gray-500">
            <div className="grid grid-cols-12 h-8 px-2 border-b border-gray-500 bg-gray-100 text-lg">
              <div className="px-2 col-span-2 border-r">User</div>
              <div className="px-2 col-span-2 border-r">Data</div>
              <div className="px-2 col-span-4 border-r">Valor anterior</div>
              <div className="px-2 col-span-4 border-r">Novo Valor</div>
            </div>
            {audits?.map((item, index) => {
              const isEven = index % 2 === 0;

              return (
                <div
                  key={item?._id}
                  className={`grid grid-cols-12 h-8 px-2 border-b border-gray-500 ${
                    isEven ? 'bg-white' : 'bg-gray-50'
                  } `}
                >
                  <div className="px-2 col-span-2 border-r">
                    {item?.author?.name}
                  </div>
                  <div className="px-2 col-span-2 border-r">
                    {moment(item?.createdAt).format('YYYY-MM-DD HH:mm')}
                  </div>
                  <div className="px-2 col-span-4 border-r">
                    {'' + item?.oldValue}
                  </div>
                  <div className="px-2 col-span-4 border-r">
                    {'' + item?.newValue}
                  </div>
                </div>
              );
            })}
          </div>
        )
      }
      title={<div className="text-lg">{label}</div>}
      trigger="click"
    >
      <ClockCircleOutlined
        style={{ width: '1rem', height: 'auto' }}
        className="ml-1 flex-shrink-0"
      ></ClockCircleOutlined>
    </Popover>
  );
};

export default AuditLog;
