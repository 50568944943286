import React, { useCallback, useEffect, useState } from 'react';
import { Tag, Select } from 'antd4';
import op from 'object-path';

import './Fields.scss';
import AuditLog from '../../../components/AuditLog';

const { Option } = Select;

const SelectField = ({
  path,
  multiple,
  mode = 'tags',
  width,
  label,
  options,
  data,
  audit = [],
  updateField
}) => {
  let dataValue = op.get(data, path);
  const [value, setValue] = useState(dataValue);

  const isMultiple = multiple;

  useEffect(() => {
    const updateValue = op.get(data, path);
    setValue(updateValue);
  }, [data, path]);
  const [statusClass, setStatusClass] = useState('');

  const myAudit = React.useMemo(
    () => audit?.filter(item => item?.path === path),
    [audit, path]
  );

  const onSave = useCallback(
    async value => {
      const response = await updateField(path, value);
      if (response?.ok) {
        //console.log('response ok, setting save-success');
        setStatusClass('save-success');

        setTimeout(() => {
          setStatusClass('');
        }, 3000);
      } else {
        setStatusClass('save-error');
      }
    },
    [path, updateField]
  );

  // console.log({
  //   path,
  //   finalValue:
  //     isMultiple || mode === 'tags'
  //       ? maybeEmptyArray(value)
  //       : !value
  //       ? undefined
  //       : value,
  //   isMultipleOrtags: isMultiple || mode === 'tags'
  // });

  return (
    <div
      key={path}
      className={`input-container d-flex items-stretch w-${width ?? '25'}`}
    >
      <div className="input-tag-container h-full">
        <Tag className="input-prefix border-radius flex-shrink-0 mr-0">
          {label ?? ''}
          <AuditLog
            audits={myAudit}
            label={<>Histórico{label ? ` - ${label}` : ``}</>}
          ></AuditLog>
        </Tag>
      </div>
      <Select
        className={`input h-full input-select border-l-0 ${statusClass}`}
        showSearch={true}
        mode={mode}
        multiple={multiple}
        placeholder=""
        value={
          isMultiple || mode === 'tags'
            ? maybeEmptyArray(value)
            : !value
            ? undefined
            : value
        }
        filterOption={(input, option) => {
          console.log({ input, option });
          return (
            option?.value?.toLowerCase?.()?.indexOf?.(input?.toLowerCase?.()) >=
            0
          );
        }}
        onSelect={(newValue, option) => {
          if (isMultiple) {
            const newState = maybeEmptyArray(value);
            if (!newState.includes(newValue)) {
              newState.push(newValue);
            }
            setValue(newState);
            return onSave(newState);
          }

          setValue(newValue);
          onSave(newValue);
        }}
        onDeselect={newValue => {
          if (isMultiple) {
            const newState = value?.filter(x => x !== newValue) ?? [];
            setValue(newState);
            onSave(newState);
          } else {
            setValue([]);
            onSave('');
          }
        }}
        onClear={() => {
          setValue([]);
          onSave([]);
        }}
      >
        {options.map(opt => (
          <Option key={opt.value} value={opt.value}>
            {opt.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

const maybeEmptyArray = value => {
  return !value ? [] : Array.isArray(value) ? value : [value];
};

export default SelectField;
