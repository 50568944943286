import React, { Fragment } from 'react';
import autobind from 'auto-bind';

import { Field, TextAreaField, Hr, Placeholder } from '../../../fields';

import '../../../fields/Fields.scss';

class Contacts extends React.Component {
  constructor(props) {
    super(props);

    autobind(this);
  }

  render() {
    return (
      <Fragment key={'contact'}>
        <h4 className="w-100">Contactos</h4>
        <Field
          key={'email'}
          width={50}
          label={'E-mail'}
          path={`contact.email`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
        <Placeholder width={50}></Placeholder>
        <Field
          key={'telephone'}
          width={50}
          label={'Telefone'}
          path={`contact.telephone`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
        <Placeholder width={50}></Placeholder>
        <Field
          key={'wechat'}
          width={50}
          label={'Wechat'}
          path={`contact.wechat`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
        <Placeholder width={50}></Placeholder>
        <Hr width={100}></Hr>
        <TextAreaField
          key={'origin_fiscal_residence'}
          width={100}
          label={'Morada no Estrangeiro'}
          minRows={2}
          path={`contact.origin_fiscal_residence`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></TextAreaField>
        <TextAreaField
          key={'portugal_fiscal_residence'}
          width={100}
          label={'Morada Fiscal'}
          minRows={2}
          path={`contact.portugal_fiscal_residence`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></TextAreaField>
        <Field
          key={'portugal_fiscal_residence_document'}
          width={100}
          label={'Doc. Morada PT'}
          path={`contact.portugal_fiscal_residence_document`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
      </Fragment>
    );
  }
}

export default Contacts;
