import React from 'react';
import autobind from 'auto-bind';
import { Link } from 'react-router-dom';
import {
  Button,
  Input,
  Select,
  DatePicker,
  Popover,
  Empty,
  Badge
} from 'antd4';

import {
  ZoomInOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  ClearOutlined,
  FilterOutlined,
  NodeExpandOutlined,
  NodeCollapseOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  AutoSizer,
  Table,
  Column,
  SortDirection,
  defaultTableRowRenderer
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import op from 'object-path';
import moment from 'moment';

import './SortableTable.scss';

import { FILTERTYPES } from '../Id';

const { RangePicker } = DatePicker;
const { Option } = Select;

const AntSortIndicator = ({ sortDirection }) => {
  return sortDirection === SortDirection.ASC ? (
    <CaretUpOutlined></CaretUpOutlined>
  ) : (
    <CaretDownOutlined></CaretDownOutlined>
  );
};

const defaultRowHeight = 80;
const defaultHeaderHeight = 60;

//eslint-disable-next-line no-unused-vars
const passwordRenderer = () => {
  return <div className={`inner-cell`}>{'•'.repeat(3)}</div>;
};

const dateRenderer = ({ cellData }) => {
  const date = moment(cellData);
  const cellContent = date.isValid() ? date.format('YYYY-MM-DD') : '';
  return <div className={`inner-cell`}>{cellContent}</div>;
};

const cellRenderer = ({ cellData }) => {
  return <div className={`inner-cell`}>{cellData}</div>;
};

//eslint-disable-next-line no-unused-vars
const yesNoRenderer = ({ cellData }) => {
  return <div className={`inner-cell`}>{cellData ? 'Sim' : 'Não'}</div>;
};
const yesRenderer = ({ cellData }) => {
  return cellData ? (
    <div className={`inner-cell color-red font-500`}>Sim</div>
  ) : null;
};

const actionRenderer = ({ cellData }) => {
  return (
    <div className="inner-cell h-100">
      <Link
        className="row-link h-100 d-flex flex-col justify-content-center align-items-center hover-bg-blue button-invisible"
        to={location =>
          `${location.pathname.replace(/\/$/, '')}/client/${cellData}/`
        }
      >
        <ZoomInOutlined style={{ fontSize: 20 }}></ZoomInOutlined>
      </Link>
    </div>
  );
};

const notificationRenderer = ({ cellData, dataKey, rowIndex }) => {
  const notifications = cellData ?? [];
  const notificationCount = notifications.filter(x => !!x && x?.read !== true)
    .length;
  // console.log({ notifications, notificationCount });
  return notificationCount ? (
    <div className={`inner-cell`}>
      <Badge count={notificationCount}></Badge>
    </div>
  ) : null;
};

const columnProps = {
  index: {
    style: {
      width: 18,
      flexGrow: 0.5
    },
    props: {}
  },
  'main.dossier': {
    style: {
      width: 50,
      flexGrow: 1
    },
    props: { cellRenderer }
  },
  'main.juris': {
    style: {
      width: 50,
      flexGrow: 1
    },
    props: { cellRenderer }
  },
  'main.situacao': {
    style: {
      width: 80,
      flexGrow: 2
    },
    props: { cellRenderer }
  },
  'main.observacao_geral': {
    style: {
      width: 100,
      flexGrow: 3
    },
    // props: { cellRenderer }  // using custom cell renderer in column
    props: {
      className: 'rv-overflow-column'
    }
  },
  'dropout.is_dropout': {
    style: {
      width: 60,
      flexGrow: 1
    },
    props: { cellRenderer: yesRenderer }
  },
  'main.applicant': {
    style: {
      width: 80,
      flexGrow: 2
    },
    props: { cellRenderer }
  },
  'main.nome': {
    style: {
      width: 80,
      flexGrow: 2
    },
    props: { cellRenderer }
  },
  notification: {
    style: {
      width: 50,
      flexGrow: 1
    },
    props: { cellRenderer: notificationRenderer }
  },
  _id: {
    style: {
      width: 30,
      flexGrow: 1
    },
    props: { cellRenderer: actionRenderer, className: 'no-hover-effects' }
  },
  'main.nif': {
    style: {
      width: 80,
      flexGrow: 2
    },
    props: { cellRenderer }
  },
  'main.nif_data_pedido': {
    style: {
      width: 80,
      flexGrow: 2
    },
    props: { cellRenderer: dateRenderer }
  },
  'main.nif_data_recebido': {
    style: {
      width: 80,
      flexGrow: 2
    },
    props: { cellRenderer: dateRenderer }
  },
  'main.password': {
    style: {
      width: 50,
      flexGrow: 1
    }
  },
  'main.data_pedido_password': {
    style: { width: 80, flexGrow: 2 },
    props: { cellRenderer: dateRenderer }
  },
  'main.nacionalidade': {
    style: {
      width: 80,
      flexGrow: 2
    },
    props: { cellRenderer }
  },
  'agency.name': {
    style: { width: 80, flexGrow: 2 },
    props: {}
  },
  'at_debt_robot.has_debt': {
    style: {
      width: 80,
      flexGrow: 2
    },
    props: { cellRenderer: yesRenderer }
  }
};

export default class SortableTable extends React.Component {
  tableRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = { filters: {}, expandedIndexes: {} };

    autobind(this);
  }

  rowGetter({ index }) {
    return this.props.data?.[index] ?? {};
  }

  cellDataGetter({ columnData, dataKey, rowData }) {
    return op.get(rowData, dataKey);
  }

  async componentDidUpdate(prevProps, prevState) {
    const filterChanged = prevState.filters !== this.state.filters;

    if (filterChanged) {
      // console.log('filtering by filters ', this.state.filters);
      return this.props.onFilter?.(this.state.filters);
    }
    const dataChanged = prevProps.data !== this.props.data;
    const expandChanged =
      prevState.expandedIndexes !== this.state.expandedIndexes;

    if (expandChanged) {
      return this.tableRef?.current?.recomputeRowHeights();
    }

    if (dataChanged || filterChanged) {
      if (this.props.expandAll) {
        await this.props.toggleExpandAll?.();
      } else {
        this.setState({ expandedIndexes: {} });
      }
    }

    const expandAllChanged = prevProps.expandAll !== this.props.expandAll;
    if (expandAllChanged) {
      if (this.props.expandAll) {
        this.setState(state => {
          const result = {};
          for (let i = 0; i < this.props.data.length; i++) {
            result[i] = true;
          }
          return { expandedIndexes: result };
        });
      } else {
        this.setState({ expandedIndexes: {} });
      }
    }
  }

  filter(dataKey, value, type) {
    const notNullish = value !== null && typeof value !== 'undefined';
    const filter = notNullish
      ? { [dataKey]: { value, type } }
      : { [dataKey]: null };
    this.setState(state => {
      return {
        filters: { ...state.filters, ...filter }
      };
    });
  }

  getFilter(dataKey) {
    const value = this.state.filters?.[dataKey]?.value;
    const notNullish = value !== null && typeof value !== 'undefined';
    switch (dataKey) {
      case 'index':
        return (
          <div
            className={'d-flex justify-content-start align-items-center w-100'}
          >
            <FilterOutlined />
          </div>
        );

      case 'dropout.is_dropout':
        return (
          <div
            className={'d-flex justify-content-start align-items-center w-100'}
          >
            <Select
              className={'ant-input-small mr-1'}
              defaultValue={value ?? ''}
              disabled={this.state.loading}
              placeholder=""
              onSelect={(newValue, option) => {
                console.log({ newValue, option });
                const isTrue = newValue === 'true';
                return this.filter(dataKey, isTrue, FILTERTYPES.boolean);
              }}
            >
              <Option className="font-1" key={'true'} value={'true'}>
                Sim
              </Option>
              <Option className="font-1" key={'false'} value={'false'}>
                Não
              </Option>
            </Select>
            <ClearOutlined
              style={{
                display: notNullish ? 'flex' : 'none',
                color: 'red'
              }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                return this.filter(dataKey, null, FILTERTYPES.boolean);
              }}
            ></ClearOutlined>
          </div>
        );
      case 'main.nacionalidade':
        return (
          <div
            className={'d-flex justify-content-start align-items-center w-100'}
          >
            <Input
              className={'ant-input-small mr-1'}
              value={value ?? ''}
              disabled={this.state.loading}
              onChange={event => {
                this.filter(dataKey, event.target.value, FILTERTYPES.string);
              }}
            />
            <ClearOutlined
              style={{
                display: notNullish ? 'flex' : 'none',
                color: 'red'
              }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                return this.filter(dataKey, null, FILTERTYPES.string);
              }}
            ></ClearOutlined>
          </div>
        );
      case 'main.nif_data_recebido':
      // totally intentional genius fallthrough
      case 'main.nif_data_pedido':
        return (
          <div
            className={'d-flex justify-content-start align-items-center w-100'}
          >
            <Popover
              className="mr-1"
              content={
                <RangePicker
                  className={'ant-input-small'}
                  value={value ?? ''}
                  disabled={this.state.loading}
                  onClick={e => e.stopPropagation()}
                  onChange={values => {
                    this.filter(dataKey, values, FILTERTYPES.daterange);
                  }}
                  format="YYYY-MM-DD"
                  prefix={<FilterOutlined />}
                />
              }
              title="Filtrar"
              trigger="click"
            >
              <Button onClick={e => e.stopPropagation()} size="small">
                Filtrar
              </Button>
            </Popover>
            <ClearOutlined
              style={{
                display: notNullish ? 'flex' : 'none',
                color: 'red'
              }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                return this.filter(dataKey, null, FILTERTYPES.string);
              }}
            ></ClearOutlined>
          </div>
        );
      case 'at_debt_robot.has_debt':
        return (
          <div
            className={'d-flex justify-content-start align-items-center w-100'}
          >
            <Select
              className={'ant-input-small mr-1'}
              defaultValue={value ?? ''}
              disabled={this.state.loading}
              placeholder=""
              onSelect={(newValue, option) => {
                console.log({ newValue, option });
                const isTrue = newValue === 'true';
                return this.filter(dataKey, isTrue, FILTERTYPES.boolean);
              }}
            >
              <Option className="font-1" key={'true'} value={'true'}>
                Sim
              </Option>
              <Option className="font-1" key={'false'} value={'false'}>
                Não
              </Option>
            </Select>
            <ClearOutlined
              style={{
                display: notNullish ? 'flex' : 'none',
                color: 'red'
              }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                return this.filter(dataKey, null, FILTERTYPES.boolean);
              }}
            ></ClearOutlined>
          </div>
        );

      default:
        return null;
    }
  }

  headerRenderer({
    columnData,
    dataKey,
    disableSort,
    label,
    sortBy,
    sortDirection
  }) {
    const filter = this.getFilter(dataKey);
    const noPaddingClass =
      dataKey === 'dropout.is_dropout' ? ' no-padding ' : '';
    return (
      <div
        className={
          'd-flex flex-col justify-content-start align-items-center w-100 h-100'
        }
      >
        <div
          className={
            'inner-header d-flex justify-content-start align-items-center w-100 ' +
            (sortBy !== dataKey ? 'sort-inactive' : '')
          }
        >
          <div>{label}</div>
          {!disableSort &&
            (sortBy === dataKey ? (
              <AntSortIndicator sortDirection={sortDirection} />
            ) : (
              <AntSortIndicator sortDirection={sortDirection} />
            ))}
        </div>
        <div
          className={
            'header-filter d-flex justify-content-start align-items-center w-100 ' +
            noPaddingClass
          }
        >
          {filter}
        </div>
      </div>
    );
  }

  rowRenderer(props) {
    const { index, style, className, key, rowData } = props;

    if (this.state.expandedIndexes[index]) {
      return (
        <div
          style={{
            ...style,
            display: 'flex',
            flexDirection: 'column'
          }}
          className={className}
          key={key}
        >
          {defaultTableRowRenderer({
            ...props,
            style: { width: style.width, height: defaultRowHeight }
          })}
          {rowData.family?.map?.((member, familyIndex) => {
            return (
              <div
                key={member?._id + '-' + familyIndex}
                aria-rowindex={props.index + '-' + familyIndex}
                className="ReactVirtualized__Table__row relative-row"
                role="row"
                style={{ width: '100%', height: defaultRowHeight }}
              >
                {Object.keys(columnProps).map((key, columnIndex) => {
                  let cellData = op.get(member, key);
                  if (key === '_id') {
                    cellData = '';
                  } else {
                    cellData =
                      columnProps[key].props?.cellRenderer?.({ cellData }) ??
                      cellData;
                  }

                  return (
                    <div
                      key={key}
                      aria-colindex={columnIndex}
                      className="ReactVirtualized__Table__rowColumn"
                      role="gridcell"
                      title={cellData}
                      style={{
                        overflow: 'hidden',
                        flexBasis: columnProps[key].style.width ?? 50,
                        flexShrink: columnProps[key].style.flexShrink ?? 1,
                        flexGrow: columnProps[key].style.flexGrow ?? 1
                      }}
                    >
                      {cellData}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    }
    return defaultTableRowRenderer(props);
  }

  getRowHeight({ index }) {
    if (this.state.expandedIndexes[index]) {
      const familySize = this.props.data?.[index]?.family?.length ?? 1;
      // console.log(
      //   'Row ' + index + ' expanded. sizes are: ',
      //   familySize,
      //   ' result is ',
      //   defaultRowHeight * (familySize + 1)
      // );
      return defaultRowHeight * (familySize + 1);
    } else {
      // console.log('Row ' + index + ' not expanded');
      return defaultRowHeight;
    }
  }

  render() {
    // if (process.env.NODE_ENV === 'development' && this.props.data?.length) {
    //   return (
    //     <Redirect
    //       to={`id/client/${this.props.data?.[0]?._id}?tab=1`}
    //     ></Redirect>
    //   );
    // }

    return (
      <AutoSizer>
        {({ width, height }) => (
          <Table
            ref={this.tableRef}
            headerHeight={defaultHeaderHeight}
            height={height}
            noRowsRenderer={() => (
              <Empty
                className="w-100 h-100 d-flex flex-col justify-content-center align-items-center"
                description={<span>Sem dados</span>}
              ></Empty>
            )}
            overscanRowCount={10}
            rowHeight={this.getRowHeight}
            rowGetter={this.rowGetter}
            rowRenderer={this.rowRenderer}
            rowClassName={({ index }) => {
              return index % 2 === 0 ? 'even-row' : 'odd-row';
            }}
            rowCount={this.props.data?.length ?? 0}
            sort={this.props.onSort}
            sortBy={this.props.sortBy.value}
            sortDirection={this.props.sortDirection.value}
            width={width}
          >
            <Column
              label="N"
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              cellRenderer={({ rowIndex, rowData }) => (
                <div className="inner-cell text-muted">
                  {Array.isArray(rowData?.family) ? (
                    rowIndex + 1
                  ) : (
                    <UserOutlined></UserOutlined>
                  )}
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.setState(state => {
                        const result = { ...state.expandedIndexes };
                        if (result[rowIndex]) {
                          result[rowIndex] = false;
                        } else {
                          result[rowIndex] = true;
                        }
                        return { expandedIndexes: result };
                      })
                    }
                  >
                    {this.state.expandedIndexes[rowIndex] ? (
                      <NodeCollapseOutlined />
                    ) : (
                      <NodeExpandOutlined />
                    )}
                  </div>
                </div>
              )}
              dataKey="index"
              disableSort={true}
              {...columnProps['index'].style}
              {...columnProps['index'].props}
            />
            <Column
              dataKey="main.dossier"
              label="Dossier"
              cellDataGetter={this.cellDataGetter}
              disableSort={false}
              headerRenderer={this.headerRenderer}
              {...columnProps['main.dossier'].style}
              {...columnProps['main.dossier'].props}
            />
            <Column
              disableSort={false}
              cellDataGetter={this.cellDataGetter}
              label="Juris"
              dataKey="main.juris"
              headerRenderer={this.headerRenderer}
              {...columnProps['main.juris'].style}
              {...columnProps['main.juris'].props}
            />
            <Column
              disableSort={true}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label="Situação"
              dataKey="main.situacao"
              {...columnProps['main.situacao'].style}
              {...columnProps['main.situacao'].props}
            />
            <Column
              disableSort={true}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label="Obs. Geral"
              dataKey="main.observacao_geral"
              {...columnProps['main.observacao_geral'].style}
              {...columnProps['main.observacao_geral'].props}
              cellRenderer={({ cellData }) => {
                return (
                  <div className={`inner-cell`}>
                    <Popover
                      style={{ height: '100%' }}
                      content={<div>{cellData}</div>}
                    >
                      <div>{cellData}</div>
                    </Popover>
                  </div>
                );
              }}
            />
            <Column
              disableSort={false}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label="Desist."
              dataKey="dropout.is_dropout"
              {...columnProps['dropout.is_dropout'].style}
              {...columnProps['dropout.is_dropout'].props}
            />
            <Column
              disableSort={false}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label="Requer."
              dataKey="main.applicant"
              {...columnProps['main.applicant'].style}
              {...columnProps['main.applicant'].props}
            />
            <Column
              disableSort={false}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label="Nome"
              dataKey="main.nome"
              {...columnProps['main.nome'].style}
              {...columnProps['main.nome'].props}
            />
            <Column
              disableSort={false}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label="Avisos"
              dataKey="notification"
              {...columnProps['notification'].style}
              {...columnProps['notification'].props}
            />
            <Column
              disableSort={true}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label={<ZoomInOutlined style={{ fontSize: 16 }}></ZoomInOutlined>}
              dataKey="_id"
              {...columnProps['_id'].style}
              {...columnProps['_id'].props}
            />
            <Column
              disableSort={false}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label="NIF"
              dataKey="main.nif"
              {...columnProps['main.nif'].style}
              {...columnProps['main.nif'].props}
            />
            <Column
              disableSort={false}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label="Data Ped."
              dataKey="main.nif_data_pedido"
              {...columnProps['main.nif_data_pedido'].style}
              {...columnProps['main.nif_data_pedido'].props}
            />
            <Column
              disableSort={false}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label="Data Rec."
              dataKey="main.nif_data_recebido"
              {...columnProps['main.nif_data_recebido'].style}
              {...columnProps['main.nif_data_recebido'].props}
            />

            <Column
              disableSort={false}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label="Data Ped."
              dataKey="main.data_pedido_password"
              {...columnProps['main.data_pedido_password'].style}
              {...columnProps['main.data_pedido_password'].props}
            />
            <Column
              disableSort={false}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label="Nacionalidade"
              dataKey="main.nacionalidade"
              {...columnProps['main.nacionalidade'].style}
              {...columnProps['main.nacionalidade'].props}
            />
            <Column
              disableSort={false}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label="Agência"
              dataKey="agency.name"
              {...columnProps['agency.name'].style}
              {...columnProps['agency.name'].props}
            />
            <Column
              disableSort={false}
              cellDataGetter={this.cellDataGetter}
              headerRenderer={this.headerRenderer}
              label="Dívidas AT"
              dataKey="at_debt_robot.has_debt"
              {...columnProps['at_debt_robot.has_debt'].style}
              {...columnProps['at_debt_robot.has_debt'].props}
            />
          </Table>
        )}
      </AutoSizer>
    );
  }
}

export { SortableTable as List };
