import { Select, Tag } from 'antd4';
import op from 'object-path';
import React, { useCallback, useEffect, useState } from 'react';
import countries from '../../../common/countries.json';
import './Fields.scss';
import AuditLog from '../../../components/AuditLog';

const { Option } = Select;

const FieldNationality = ({
  path,
  width,
  label,
  editable,
  disabled,
  data,
  audit = [],
  updateField
}) => {
  let dataValue = op.get(data, path);
  const [selectValue, setSelectValue] = useState(dataValue);
  const [isDirty, setDirty] = useState(false);

  useEffect(
    value => {
      const updateValue = op.get(data, path);
      setSelectValue(updateValue);
    },
    [data, path]
  );

  const myAudit = React.useMemo(
    () => audit?.filter(item => item?.path === path),
    [audit, path]
  );

  const [statusClass, setStatusClass] = useState('');
  const onSave = useCallback(
    async valueChanged => {
      // console.log('changedValue', event, event?.target, event?.target?.value);
      const value = valueChanged; //?.target?.value;
      if (isDirty) {
        const response = await updateField(path, value);
        if (response?.ok) {
          // console.log('response ok, setting dirty to FALSE. adding save-success' );
          setDirty(false);
          setStatusClass('save-success');

          setTimeout(() => {
            setStatusClass('');
          }, 3000);
        } else {
          setStatusClass('save-error');
        }
      } else {
        // console.log('not dirty');
      }
    },
    [isDirty, updateField, path]
  );

  if (!op.has(data, path)) {
    return null;
  }
  //
  return (
    <div
      key={path}
      className={`input-container flex items-center justify-start w-${width ??
        '25'}`}
    >
      {!disabled && (
        <div className="input-tag-container h-full">
          <Tag className="input-prefix border-radius flex-shrink-0 mr-0">
            <AuditLog
              audits={myAudit}
              label={<>Histórico{label ? ` - ${label}` : ``}</>}
            ></AuditLog>
          </Tag>
        </div>
      )}
      <Select
        className={'input ' + statusClass + ' w-full input-select'}
        value={selectValue}
        disabled={disabled}
        onBlur={event => {
          // console.log('onBlur', event);
          if (editable === false) {
            event.preventDefault();
            return null;
          }

          onSave(selectValue);
        }}
        onChange={value => {
          //   console.log('onChange', value);

          setDirty(true);
          setSelectValue(value);
        }}
        prefix={<Tag className="input-prefix border-radius">{label}</Tag>}
        //height={50}
        key={'nacionalidade'}
        showSearch
        placeholder="Selecione uma nacionalidade"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {countries.map(country => (
          <Option key={country} value={country}>
            {country}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default FieldNationality;
