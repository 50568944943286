import React, { Fragment } from 'react';
import autobind from 'auto-bind';

import {
  Field,
  DateField,
  TextAreaField,
  CheckboxField,
  SelectField,
  Hr,
  Placeholder
} from '../../../fields';

import '../../../fields/Fields.scss';

class Dropout extends React.Component {
  constructor(props) {
    super(props);

    autobind(this);
  }

  render() {
    return (
      <Fragment key={'dropout'}>
        <h4 className="w-100">Desistência</h4>
        <CheckboxField
          key={'is_dropout'}
          label={'Desistente'}
          width={100}
          path={`dropout.is_dropout`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></CheckboxField>
        <DateField
          showTime={false}
          key={'communication_date'}
          label={'Data Comunicação'}
          width={33}
          path={`dropout.communication_date`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></DateField>
        <DateField
          showTime={false}
          key={'change_date'}
          label={'Data Alteração'}
          width={33}
          path={`dropout.change_date`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></DateField>
        <Placeholder width={33}></Placeholder>
        <Field
          key={'new_representative'}
          width={33}
          label={'Novo Representante'}
          path={`dropout.new_representative`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
        <Field
          key={'golden_visa_dropout'}
          width={33}
          label={'Desist. Golden Visa'}
          path={`dropout.golden_visa_dropout`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
        <Placeholder width={33}></Placeholder>
        <Hr width={100}></Hr>
        <SelectField
          key={'revocation_signed'}
          width={33}
          label={'Revogação Assinada'}
          options={[
            { label: 'Sim', value: 'yes' },
            { label: 'Não', value: 'no' }
          ]}
          path={`dropout.revocation_signed`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></SelectField>
        <DateField
          showTime={false}
          key={'revocation_signed_date'}
          label={'Data Assinatura'}
          width={33}
          path={`dropout.revocation_signed_date`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></DateField>
        <Placeholder width={33}></Placeholder>
        <DateField
          showTime={false}
          key={'revocation_date'}
          label={'Data Envio RV'}
          width={33}
          path={`dropout.revocation_date`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></DateField>
        {/* <NumberField
          key={'in_debt'}
          label={'Valor em Dívida'}
          width={33}
          path={`dropout.in_debt`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></NumberField>
        <DateField
          showTime={false}
          key={'notification_date'}
          label={'Data de Notificação'}
          width={33}
          path={`dropout.notification_date`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></DateField>
        <NumberField
          key={'agreed_value'}
          label={'Valor Acertado'}
          width={33}
          path={`dropout.agreed_value`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></NumberField> */}
        <Placeholder width={66}></Placeholder>
        <Hr width={100}></Hr>
        <TextAreaField
          key={'observations'}
          width={100}
          label={'Observações'}
          minRows={2}
          path={`dropout.observations`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></TextAreaField>
      </Fragment>
    );
  }
}

export default Dropout;
