import { Storage } from 'aws-amplify';
import uuidv4 from 'uuid/v4';
import { address } from '../../../common/variables';
import { handleResponse, authenticate } from './common';

/* Real Data setup */

const folderName = 'id/';

/* True requests section */

const BE_ADDR = address;

const getFullClients = async () => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'id/client/full', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
};

const getClients = async () => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'id/client', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
};

const getClient = async _id => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/client/${_id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
};

const getAudit = async (_id, entityType) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let url;

  switch (entityType) {
    case 'id_client': // intentional fallthrough
    case 'client':
      url = `audit/id_client/${_id}/`;
      break;
    case 'id_relative': // intentional fallthrough
    case 'relative':
      url = `audit/id_relative/${_id}/`;
      break;
    default:
      throw new Error('Invalid entity type');
  }

  const response = await fetch(BE_ADDR + url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
};

const postClient = async client => {
  console.log(
    `POST /id/client, body: {  ${JSON.stringify(client, null, 2)}}} `
  );
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'id/client', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(client)
  });
  return await handleResponse(response);
};

const postRelative = async relative => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'id/relative', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(relative)
  });
  return await handleResponse(response);
};

const deleteClient = async clientId => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/client/${clientId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
};

const deleteRelative = async relativeId => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/relative/${relativeId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
};
const putClient = async (clientId, path, value) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/client/${clientId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ path, value })
  });
  return await handleResponse(response);
};

const putRelative = async (relativeId, path, value) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/relative/${relativeId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ path, value })
  });
  return await handleResponse(response);
};

const postArrayElementClient = async (path, clientId, value) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/client/${clientId}/array`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ path, value: value ?? {} })
  });

  return await handleResponse(response);
};

const postArrayElementRelative = async (path, relativeId, value) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/relative/${relativeId}/array`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ path, value: value ?? {} })
  });
  return await handleResponse(response);
};

const deleteArrayElementClient = async (path, _id, clientId) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/client/${clientId}/array`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ path, _id })
  });
  return await handleResponse(response);
};

const deleteArrayElementRelative = async (path, _id, relativeId) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/relative/${relativeId}/array`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ path, _id })
  });
  return await handleResponse(response);
};

const uploadFile = async file => {
  const random = uuidv4();
  const fileID = random + '-' + file.name;

  const customPrefix = {
    public: folderName
  };

  let s3Result;
  try {
    s3Result = await Storage.put(fileID, file, {
      contentType: file.type,
      customPrefix: customPrefix
    });
  } catch (error) {
    return { ok: false, data: s3Result };
  }

  return { ok: true, data: s3Result };
};

const removeFile = async s3Key => {
  const customPrefix = {
    public: folderName
  };

  let s3Result;
  try {
    s3Result = await Storage.remove(s3Key, {
      customPrefix: customPrefix
    });
  } catch (error) {
    return { ok: false, data: s3Result };
  }

  return { ok: true, data: s3Result };
};

const getFileLink = async key => {
  const customPrefix = {
    public: folderName
    // protected: 'protected/',
    // private: 'private/'
  };
  const result = await Storage.get(key, {
    customPrefix: customPrefix,
    expires: 600
  });
  console.log('fileLink: ', result);

  return result;
};

const routes = {
  getFullClients,
  getClients,
  getClient,
  postClient,
  postRelative,
  deleteClient,
  deleteRelative,
  putClient,
  putRelative,
  postArrayElementClient,
  postArrayElementRelative,
  deleteArrayElementClient,
  deleteArrayElementRelative,
  uploadFile,
  getFileLink,
  removeFile,
  getAudit
};

export default routes;
