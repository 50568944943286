import React from 'react';

import {
  Field,
  DateField,
  TextAreaField,
  NumberField,
  SelectField,
  CheckboxField,
  UploadField
} from '../../../fields';
import '../../../fields/Fields.scss';

import {
  ArrayTitle,
  ActionAddArrayElement,
  ActionDeleteArrayElement
} from '../array';

const paymentTypeOptions = [
  { label: 'IMI', value: 'IMI' },
  { label: 'Repres. Fiscal', value: 'Repres. Fiscal' },
  { label: 'IRS', value: 'IRS' },
  { label: 'IS', value: 'IS' }
];

const dataPath = 'payments';

const Payments = ({
  data,
  audit,
  addArrayElement,
  deleteArrayElement,
  updateField
}) => {
  const [filteredData, setFilteredData] = React.useState(data);

  React.useEffect(() => {
    // Reset filters and cache when new data comes in
    setFilteredData(data);
  }, [data]);

  return (
    <div className="w-full flex flex-col justify-start items-start no-field-padding payments overflow-hidden">
      <div className="flex justify-content-start align-items-center pb-2">
        <ArrayTitle title="Pagamentos"></ArrayTitle>
        <ActionAddArrayElement
          onClick={() => addArrayElement(dataPath)}
        ></ActionAddArrayElement>
      </div>

      <div className="flex flex-row justify-start items-stretch w-full bg-gray-200">
        <div className="w-1/12 border-r border-gray-400 px-2 py-1 flex items-end">
          Valor Avisado
        </div>
        <div className="w-1/12 border-r border-gray-400 px-2 py-1 flex items-end">
          Data Avisado
        </div>
        <div className="w-1/12 border-r border-gray-400 px-2 py-1 flex items-end">
          Data Recebimento
        </div>
        <div className="w-1/12 border-r border-gray-400 px-2 py-1 flex items-end">
          Valor Recebimento
        </div>
        <div className="w-1/12 border-r border-gray-400 px-2 py-1 flex items-end">
          Tipo de Pagamento
        </div>
        <div className="w-2/12 border-r border-gray-400 px-2 py-1 flex items-end">
          Observação
        </div>
        <div className="w-1/12 border-r border-gray-400 px-2 py-1 flex items-end">
          Referência do Pagamento
        </div>
        <div className="w-1/12 border-r border-gray-400 px-2 py-1 flex items-end">
          Pago ao Estado
        </div>
        <div className="w-2/12 border-r border-gray-400 px-2 py-1 flex items-end">
          Documento
        </div>
        <div className="w-1/12 border-r border-gray-400 px-2 py-1 flex items-end">
          Ações
        </div>
      </div>

      {filteredData?.[dataPath]?.map((item, index) => {
        return (
          <SinglePayment
            key={item._id}
            data={data}
            audit={audit}
            dataIndex={index}
            deleteArrayElement={deleteArrayElement}
            updateField={updateField}
          ></SinglePayment>
        );
      })}
    </div>
  );
};

export default Payments;

const SinglePayment = ({
  data,
  audit,
  dataIndex,
  updateField,
  deleteArrayElement
}) => {
  const element = data?.[dataPath]?.[dataIndex] ?? {};
  return (
    <div
      key={'' + dataPath + dataIndex}
      className={`fields-container array-item array-item-${
        dataIndex % 2 === 0 ? 'even' : 'odd'
      } w-full justify-start items-stretch border-l border-gray-300`}
    >
      <NumberField
        width={'1/12'}
        key={'warned_value'}
        label={''}
        path={`payments.${dataIndex}.warned_value`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></NumberField>
      <DateField
        showTime={false}
        key={'warned_date'}
        width={'1/12'}
        label={''}
        path={`payments.${dataIndex}.warned_date`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></DateField>
      <DateField
        showTime={false}
        key={'received_date'}
        width={'1/12'}
        label={''}
        path={`payments.${dataIndex}.received_date`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></DateField>
      <NumberField
        key={'received_value'}
        width={'1/12'}
        label={''}
        path={`payments.${dataIndex}.received_value`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></NumberField>
      <SelectField
        key={'payment_type'}
        multiple={true}
        width={'1/12'}
        label={''}
        options={paymentTypeOptions}
        path={`payments.${dataIndex}.payment_type`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></SelectField>
      <Field
        key={'observation'}
        width={'2/12'}
        label={''}
        path={`payments.${dataIndex}.observation`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></Field>
      <Field
        key={'payment_reference'}
        width={'1/12'}
        label={''}
        path={`payments.${dataIndex}.payment_reference`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></Field>
      <CheckboxField
        key={'paid_to_state'}
        width={'1/12'}
        label={''}
        path={`payments.${dataIndex}.paid_to_state`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></CheckboxField>
      <UploadField
        key={'document'}
        label={''}
        width={'2/12'}
        path={`payments.${dataIndex}.document`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></UploadField>
      <div className="w-1/12 px-2 border-radius border border-gray-300 flex items-center">
        <ActionDeleteArrayElement
          onClick={() => deleteArrayElement(dataPath, element?._id)}
        ></ActionDeleteArrayElement>
      </div>
    </div>
  );
};
