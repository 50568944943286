import React, { Fragment } from 'react';
import autobind from 'auto-bind';
import {
  Field,
  DateField,
  TextAreaField,
  CheckboxField,
  UploadField,
  Hr,
  Placeholder,
  FieldNationality,
  SelectField,
  TitleField
} from '../../../fields';

import '../../../fields/Fields.scss';
import CustomLabel from '../../../fields/CustomLabelReadOnly';
import moment from 'moment';
import WrongPasswordInfo from '../../../fields/WrongPasswordInfo';

class Main extends React.Component {
  constructor(props) {
    super(props);

    autobind(this);
  }

  render() {
    return (
      <Fragment key={'main'}>
        <h4 className="w-100">Dados Principais</h4>
        <Field
          key={'role'}
          width={33}
          label={'Relação c/ Principal'}
          path={'main.role'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
        <CheckboxField
          key={'familiar_com_faturacao'}
          label={'Tem faturação própria'}
          width={33}
          path={'main.familiar_com_faturacao'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></CheckboxField>
        <Field
          key={'applicant'}
          width={50}
          label={'Requente'}
          path={'main.applicant'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
        <Field
          key={'nome'}
          width={50}
          label={'Nome'}
          path={'main.nome'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
        <SelectField
          key={'genero'}
          width={50}
          label={'Género'}
          mode="multiple"
          path={'main.genero'}
          multiple={false}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
          options={[
            { label: 'Feminino', value: 'Feminino' },
            {
              label: 'Masculino',
              value: 'Masculino'
            }
          ]}
        ></SelectField>
        <Field
          key={'dossier'}
          label={'Dossier'}
          path={'main.dossier'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
        <Field
          key={'juris'}
          label={'Juris'}
          path={'main.juris'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
        <SelectField
          key={'situacao'}
          width={50}
          label={'Situação'}
          mode="multiple"
          path={'main.situacao'}
          multiple={false}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
          options={[
            { label: 'Ativo', value: 'Ativo' },
            {
              label: 'Ativo — Regime Simplificado',
              value: 'Ativo — Regime Simplificado'
            },
            {
              label: 'Ativo — Contabilidade Organizada',
              value: 'Ativo — Contabilidade Organizada'
            },
            { label: 'Pack Resident', value: 'Pack Resident' },
            {
              label: 'Desistente — Alteração Representante Fiscal',
              value: 'Desistente — Alteração Representante Fiscal'
            },
            {
              label: 'Desistente — Alteração Morada Fiscal (R)',
              value: 'Desistente — Alteração Morada Fiscal (R)'
            },
            {
              label: 'Desistente — Processo de Golden Visa',
              value: 'Desistente — Processo de Golden Visa'
            },
            {
              label: 'Desistente — Notificação Eletrónica',
              value: 'Desistente — Notificação Eletrónica'
            },
            {
              label: 'Notificação Eletrónica Pedida',
              value: 'Notificação Eletrónica Pedida'
            },
            { label: 'Pedido de NIF', value: 'Pedido de NIF' },
            {
              label: 'Carta Registada — Rescisão do contrato (Imparidade)',
              value: 'Carta Registada — Rescisão do contrato (Imparidade)'
            },
            {
              label: 'Carta Registada — Rescisão do contrato (Injunção)',
              value: 'Carta Registada — Rescisão do contrato (Injunção)'
            },
            {
              label: 'Processo de Injunção em curso',
              value: 'Processo de Injunção em curso'
            },
            {
              label: 'Processo de Perda por Imparidade em curso',
              value: 'Processo de Perda por Imparidade em curso'
            },
            { label: 'Falecido', value: 'Falecido' }
          ]}
        ></SelectField>
        <SelectField
          multiple={true}
          key={'investment_type'}
          width={50}
          label={'Tipos de Investimento'}
          options={[
            { label: 'Imóveis', value: 'imoveis' },
            { label: 'Fundos', value: 'fundos' },
            { label: 'N/A', value: 'N/A' }
          ]}
          path={`main.investment_type`}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></SelectField>
        <TextAreaField
          key={'observacao_geral'}
          width={100}
          label={'Obs. Geral'}
          minRows={2}
          path={'main.observacao_geral'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></TextAreaField>
        <Hr width={100}></Hr>
        <Field
          key={'nif'}
          width={33}
          label={'NIF'}
          path={'main.nif'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
        <DateField
          showTime={false}
          key={'nif_data_pedido'}
          label={'Data pedido'}
          width={33}
          path={'main.nif_data_pedido'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></DateField>
        <DateField
          showTime={false}
          key={'nif_data_recebido'}
          label={'Data Recebido'}
          width={33}
          path={'main.nif_data_recebido'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></DateField>
        <UploadField
          key={'nif_document'}
          label={'Doc. NIF'}
          width={66}
          path={'main.nif_document'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></UploadField>
        <Placeholder width={33}></Placeholder>
        <Field
          key={'password'}
          width={33}
          label={'Password (AT)'}
          path={'main.password'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
        <WrongPasswordInfo
          key={'password_foi_corrigida'}
          label={'Password AT Errada?'}
          width={33}
          path={'at_debt_robot.wrongATPassword'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></WrongPasswordInfo>
        <DateField
          showTime={false}
          key={'data_pedido_password'}
          label={'Data Pedido'}
          width={33}
          path={'main.data_pedido_password'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></DateField>
        <Hr width={100}></Hr>
        <Field
          key={'passaporte'}
          width={50}
          label={'Passaporte'}
          path={'main.passaporte'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field>
        {/* <Field
          key={'nacionalidade'}
          width={50}
          label={'Nacionalidade'}
          path={'main.nacionalidade'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></Field> */}
        <FieldNationality
          key={'nacionalidade'}
          width={50}
          label={'Nacionalidade'}
          path={'main.nacionalidade'}
          data={this.props.data}
          audit={this.props.audit}
          updateField={this.props.updateField}
        ></FieldNationality>
        <Hr width={100}></Hr>
        <TitleField width={100} title={'Dívidas AT'}></TitleField>
        <CustomLabel
          key={'tem_divida_at'}
          width={33}
          label={'Tem Dívida AT?'}
          path={'at_debt_robot.has_debt'}
          data={this.props.data}
          editable={false}
          customRender={value => {
            if (value === undefined || value === 0) return 'Não disponível';

            return value ? 'Sim' : 'Não';
          }}
        ></CustomLabel>
        <Field
          key={'number_of_debts'}
          width={20}
          label={'Numero de Dívidas'}
          path={'at_debt_robot.number_of_debts'}
          data={this.props.data}
          editable={false}
        ></Field>
        <Field
          key={'total_divida_at'}
          width={20}
          label={'Total da Dívida'}
          path={'at_debt_robot.total_debt'}
          data={this.props.data}
          editable={false}
        ></Field>

        <CustomLabel
          key={'last_check_date'}
          width={33}
          label={'Data Última Verificação'}
          path={'at_debt_robot.last_check_date'}
          data={this.props.data}
          editable={false}
          customRender={value => {
            return value ? moment(value).format('DD/MM/YYYY') : '';
          }}
        ></CustomLabel>
        <Field
          key={'at_notes'}
          width={50}
          label={'Notas Última verificação'}
          path={'at_debt_robot.notes'}
          data={this.props.data}
          editable={false}
        ></Field>
      </Fragment>
    );
  }

  onChange(value) {
    console.log(`selected ${value}`);
  }

  onSearch(val) {
    console.log('search:', val);
  }
}

export default Main;
