import ExcelJS from 'exceljs';
import moment from 'moment';
import { notification } from 'antd4';
import { getSheetJson, getWorkbook } from './xlsParser';

const processExcelFile = async fileReadResult => {
  const workbook = await getWorkbook(fileReadResult);
  const firstSheetName = workbook?.SheetNames?.[0];
  console.log(firstSheetName);
  const firstSheet = workbook?.Sheets?.[firstSheetName];

  if (!firstSheet) {
    throw new Error(
      'Erro: Não foi possível encontrar a primeira folha (sheet) do Excel. Confirme que o Excel não está vazio'
    );
  }

  const sheetJson = await getSheetJson(firstSheet, {
    raw: false,
    rawNumbers: true,
    blankrows: false,
    defval: null,
    header: [
      'DataMov',
      'DescricaoMovimento',
      'ValorEuro',
      'OperacaoBancaria',
      'Tipo',
      'TipoEntidade',
      'CategoriaMovimento',
      'NIF',
      'AnoEntrada',
      'TipoImposto',
      'AnoImposto',
      'NomeCliente',
      'NumeroFatura',
      'Adiantamento'
    ]
    // header: [
    //   'is_paid',
    //   'first_year_paid_by_cg',
    //   'service_year',
    //   'invoice_date',
    //   'invoice_number',
    //   'value',
    //   'received_date',
    //   'value_received',
    //   'observation'
    // ]
  });

  return sheetJson;
};

const defaultBorder = {
  top: { style: 'thin' },
  left: { style: 'thin' },
  bottom: { style: 'thin' },
  right: { style: 'thin' }
};

export default async function exportPaymentImportReport(excelImportResult) {
  if (!excelImportResult?.length) {
    notification.error('Erro: Sem resultados para exportar');
    console.log('Erro: Sem resultados para exportar');
    return;
  }

  let workbook = new ExcelJS.Workbook();

  workbook.creator = 'Vicki Finpartner';
  workbook.lastModifiedBy = 'Vicki Finpartner';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible'
    }
  ];

  let worksheet = workbook.addWorksheet('Representações Fiscais');

  worksheet.columns = [
    {
      header: 'Resultado',
      key: 'result',
      width: 15,
      bgColor: { argb: 'FFFF0000' },
      border: defaultBorder
    },
    { header: 'Erro?', key: 'message', width: 15, border: defaultBorder },
    { header: 'Nome', key: 'clientName', width: 30, border: defaultBorder },
    { header: 'NIF', key: 'clientNif', width: 10, border: defaultBorder },
    {
      header: 'Valor Recebido',
      key: 'value_received',
      width: 15,
      border: defaultBorder
    },
    {
      header: 'Data Recebido',
      key: 'received_date',
      width: 15,
      border: defaultBorder
    },
    {
      header: 'Ano Serviço',
      key: 'service_year',
      width: 10,
      border: defaultBorder
    },
    {
      header: 'Nº Fattura',
      key: 'invoice_number',
      width: 15,
      border: defaultBorder
    },
    {
      header: 'Substituição? (Nº Fact)',
      key: 'isUpdate',
      width: 10,
      border: defaultBorder
    },
    {
      header: 'Adiantamento',
      key: 'isAdvance',
      width: 10,
      border: defaultBorder
    },
    {
      header: 'Observações',
      key: 'observation',
      width: 60,
      border: defaultBorder
    }
  ];

  formatColors(worksheet);

  excelImportResult.forEach(item => {
    let row = worksheet.addRow({
      result: item.result ?? ' ',
      message: item.message ?? ' ',
      clientName: item.clientName ?? ' ',
      clientNif: item.clientNif ?? ' ',
      value_received: item.value_received ?? ' ',
      received_date: item.received_date
        ? moment(item.received_date).format('YYYY-MM-DD')
        : ' ',
      service_year: item.service_year
        ? moment(item.service_year).format('YYYY')
        : ' ',
      invoice_number: item.invoice_number ?? ' ',
      isAdvance: item.clientName,
      isUpdate: !item.invoice_number
        ? 'Sem Nº Fatura'
        : item.isUpdate
        ? 'Substituída'
        : 'Novo',
      observation: item.observation ?? ' '
    });

    row.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
    row.eachCell(cell => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: item.result !== 'SUCCESS' ? 'FFF0F0F0' : '00FFFFFF'
        }
      };
      cell.border = defaultBorder;
    });
  });

  return await downloadExcel(workbook);
}

function formatColors(worksheet) {
  //create filters
  worksheet.autoFilter = {
    from: 'A1',
    to: 'I1'
  };
}

async function downloadExcel(workbook) {
  try {
    let blob = await workbook2Blob(workbook);

    let filename =
      'id_pagamentos__importacao_' +
      moment().format('YYYY-MM-DD_HH:ss') +
      '.xlsx';
    return anchorDownload(blob, filename);
  } catch (err) {
    console.error(err);
    return null;
  }
}

const workbook2Blob = async workbook => {
  let buffer = await workbook.xlsx.writeBuffer();
  let blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  return blob;
};
const anchorDownload = (blob, filename) => {
  let a = document.createElement('a');
  a.download = filename;
  a.href = URL.createObjectURL(blob);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function() {
    URL.revokeObjectURL(a.href);
  }, 1500);
};

export { processExcelFile, exportPaymentImportReport };
