import React from 'react';

import sort from 'fast-sort';
import { isNil } from 'lodash';
import {
  Field,
  DateField,
  NumberField,
  SelectField,
  CheckboxField,
  Hr,
  Placeholder,
  TextAreaField
} from '../../../fields';

import '../../../fields/Fields.scss';
import './ServicePayments.scss';
import {
  ArraySection,
  ArrayTitle,
  ActionAddArrayElement,
  ActionDeleteArrayElement,
  ArraySearch
} from '../array';

import { calculateClientDebt } from '../../../data/processClientDebt';

let dataCache;

const isValidInvoiceNumber = value => {
  // Critical: If you change this regex here, change also in backend/fp/src/route/id/bulkServicePayments.js
  return (
    !value || !value?.trim() || /^FA \d\d\d\d\/\d+$/g.test(value?.trim() ?? '')
  );
};

const dateIsValid = momentValue => {
  return (
    !momentValue ||
    (momentValue?.year &&
      momentValue.year() > 1999) /* && momentValue.year() < moment().year()*/
  );
};

const dateFormatLabel = (
  <span className="break-none">Formato "YYYY-MM-DD", de 2000 ao ano atual</span>
);

const sortAndSetIndex = items => {
  // Save the original index to use in path argument of later updateField() operations
  for (let i = 0; i < items.length; i++) {
    if (!isNil(items[i].originalDataIndex)) {
      continue;
    }
    items[i].originalDataIndex = i;
  }
  const sorted = items ? [...items] : [];

  const result = sort(sorted).asc(
    x => x?.service_year?.toISOString?.() ?? x?.service_year ?? ''
  );
  console.log(
    'sorted',
    result.map(x => x?.service_year?.toISOString?.() ?? x?.service_year ?? '')
  );

  return result;
};

const ServicePayments = ({
  data,
  audit,
  addArrayElement,
  deleteArrayElement,
  updateField
}) => {
  const [filteredData, setFilteredData] = React.useState([]);
  dataCache = [];
  const dataPath = 'service_payments';

  React.useEffect(() => {
    // Reset filters and cache when new data comes in
    const sorted = sortAndSetIndex(data[dataPath]);
    setFilteredData({ ...data, [dataPath]: sorted });
    dataCache = { ...data, [dataPath]: sorted };
  }, [data]);

  React.useEffect(() => {
    // Reset filters and cache when new data comes in
    const sorted = sortAndSetIndex(data[dataPath]);
    setFilteredData({ ...data, [dataPath]: sorted });
    dataCache = { ...data, [dataPath]: sorted };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ArraySection
      data={filteredData}
      audit={audit}
      dataPath={dataPath}
      deleteArrayElement={deleteArrayElement}
      updateField={updateField}
      renderToolbar={() => {
        return (
          <div className="flex flex-col w-100 ">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex justify-content-start align-items-center">
                <ArrayTitle title="Pagamentos de Serviço"></ArrayTitle>
              </div>

              <div className="d-flex justify-content-end align-items-center fg-1">
                <ActionAddArrayElement
                  onClick={() => addArrayElement(dataPath)}
                ></ActionAddArrayElement>
                <ArraySearch
                  dataPath={dataPath}
                  dataCache={dataCache}
                  onSearch={setFilteredData}
                ></ArraySearch>
              </div>
            </div>
            <TotalOfDebtSection data={filteredData} />
          </div>
        );
      }}
    >
      <SingleServicePayment></SingleServicePayment>
    </ArraySection>
  );
};

export default ServicePayments;

const SingleServicePayment = ({
  data,
  audit,
  dataIndex,
  // ^ Special case: we saved the real dataIndex inside each element before, and then sorted them, so this index does not reflect the DB order
  updateField,
  deleteArrayElement
}) => {
  const element = data?.service_payments?.[dataIndex] ?? {};
  // Now the real one, the index in the DB:
  const originalDataIndex = element?.originalDataIndex;
  return (
    <div
      key={'service_payments' + dataIndex}
      className={`service-payment fields-container ${
        element.is_paid ? 'array-item-green' : ''
      } array-item array-item-${
        dataIndex % 2 === 0 ? 'even' : 'odd'
      } w-100 border-radius`}
    >
      <div className="array-index d-flex justify-content-start w-100">
        <span className="d-flex justify-content-center align-items-center border-radius-50 mr-3">
          {dataIndex}
        </span>
        <ActionDeleteArrayElement
          onClick={() => deleteArrayElement('service_payments', element?._id)}
        ></ActionDeleteArrayElement>
      </div>

      <SelectField
        key={'first_year_paid_by_cg'}
        width={33}
        label={'Pago 1º ano p/ CG'}
        options={[
          { label: 'Sim', value: 'yes' },
          { label: 'Não', value: 'no' }
        ]}
        path={`service_payments.${dataIndex}.first_year_paid_by_cg`}
        data={data}
        audit={audit}
        updateField={(path, value) =>
          updateField(
            `service_payments.${originalDataIndex}.first_year_paid_by_cg`,
            value
          )
        }
      ></SelectField>
      <DateField
        showTime={false}
        key={'service_year'}
        label={'Ano do Serviço'}
        width={33}
        picker={'year'}
        path={`service_payments.${dataIndex}.service_year`}
        data={data}
        audit={audit}
        updateField={(path, value) =>
          updateField(
            `service_payments.${originalDataIndex}.service_year`,
            value
          )
        }
      ></DateField>
      <CheckboxField
        key={'is_paid'}
        label={'Pago'}
        width={25}
        path={`service_payments.${dataIndex}.is_paid`}
        data={data}
        audit={audit}
        updateField={updateField}
      ></CheckboxField>
      <Placeholder width={33}></Placeholder>
      <Hr width={66}></Hr>
      <DateField
        showTime={false}
        key={'invoice_date'}
        label={'Data da Fatura'}
        width={33}
        path={`service_payments.${dataIndex}.invoice_date`}
        isValid={dateIsValid}
        formatLabel={dateFormatLabel}
        data={data}
        audit={audit}
        updateField={(path, value) =>
          updateField(
            `service_payments.${originalDataIndex}.invoice_date`,
            value
          )
        }
      ></DateField>
      <Field
        key={'invoice_number'}
        width={33}
        label={'Nº Fatura'}
        path={`service_payments.${dataIndex}.invoice_number`}
        data={data}
        audit={audit}
        formatLabel={<span className="break-none">"FA YYYY/XXX"</span>}
        isValid={isValidInvoiceNumber}
        updateField={(path, value) =>
          updateField(
            `service_payments.${originalDataIndex}.invoice_number`,
            value
          )
        }
      ></Field>
      <Hr width={66}></Hr>
      <NumberField
        key={'value'}
        label={'Valor'}
        width={33}
        path={`service_payments.${dataIndex}.value`}
        data={data}
        audit={audit}
        updateField={(path, value) =>
          updateField(`service_payments.${originalDataIndex}.value`, value)
        }
      ></NumberField>
      <DateField
        showTime={false}
        key={'received_date'}
        label={'Data Receb.'}
        width={33}
        isValid={dateIsValid}
        formatLabel={dateFormatLabel}
        path={`service_payments.${dataIndex}.received_date`}
        data={data}
        audit={audit}
        updateField={(path, value) =>
          updateField(
            `service_payments.${originalDataIndex}.received_date`,
            value
          )
        }
      ></DateField>
      <NumberField
        key={'value_received'}
        label={'Valor Receb.'}
        width={33}
        path={`service_payments.${dataIndex}.value_received`}
        data={data}
        audit={audit}
        updateField={(path, value) =>
          updateField(
            `service_payments.${originalDataIndex}.value_received`,
            value
          )
        }
      ></NumberField>
      <TextAreaField
        key={'observacao_geral'}
        label={'Observações'}
        width={100}
        minRows={2}
        path={`service_payments.${dataIndex}.observation`}
        data={data}
        audit={audit}
        updateField={(path, value) =>
          updateField(
            `service_payments.${originalDataIndex}.observation`,
            value
          )
        }
      ></TextAreaField>
    </div>
  );
};

const TotalOfDebtSection = ({ data }) => {
  // console.log('Data', data.service_payments);

  let debt = calculateClientDebt(data.service_payments);
  //console.log('debt', debt);

  return (
    // <div className="d-flex flex-row justify-content-around bg-gray-400 p-3 w-100 bg-gray-400 text-lg">
    //GAVE UP ON TAILWIND - TODO: replace by tailwind
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: 12,
        borderRadius: 4,
        marginTop: 12,
        border: '1px solid #d9d9d9',
        backgroundColor: '#f5f8fa',
        fontSize: 14
      }}
    >
      <div>Total Faturado: {debt.totalInDebt.toFixed(2)}€</div>
      <div>Total Pago: {debt.totalPaid.toFixed(2)}€</div>
      <div>Saldo: {(debt.totalPaid - debt.totalInDebt).toFixed(2)}€</div>
    </div>
    // </div>
  );
};

/*

createdAt: "2022-01-05T20:10:18.795Z"
first_year_paid_by_cg: ""
invoice_date: null
invoice_number: ""
is_paid: false
observation: ""
received_date: null
service_year: null
updatedAt: "2022-01-05T20:10:31.129Z"
value: 100
value_received: 50
_id: "61d5fb2a1dbb0d4c64e9c45f"
*/
