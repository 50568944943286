import React from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { message } from 'antd';
import { fetchSystemState, sendIvaRecs, reset } from '../../../network/ivaRec';
import DetailedMessage from '../../../components/util/DetailedMessage';
import { exportIvaRec } from './exportExcel';

const STEPS = { idle: 0, sending: 1, sent: 2 };

const STATES = {
  idle: 'idle',
  sending: 'sending',
  sent: 'sent',
  error: 'error'
};

const Home = () => {
  const [step, setStep] = React.useState(STEPS.idle);
  const [files, setFiles] = React.useState(null);
  const [ivaRecResult, setIvaRecResult] = React.useState(null);
  const [isConfirmReset, setConfirmReset] = React.useState(false);

  const [pollTimer, setPollTimer] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const canUpload = !files?.length && !files?.length;
  const canClear = !canUpload;
  const canDeliver = !loading && !!files?.length;
  console.log({ canDeliver, loading, ivaRecLen: files?.length });

  React.useEffect(() => {
    checkState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const readFiles = async uploadEvent => {
    setLoading(true);
    uploadEvent.preventDefault();
    setFiles(
      uploadEvent.target?.files?.length ? [...uploadEvent.target?.files] : []
    );
    setLoading(false);
  };

  const doSend = async () => {
    setLoading(true);
    setIvaRecResult(null);
    try {
      const data = new FormData();

      for (const file of files) {
        data.append('files', file, file.name);
      }

      console.log(data);

      const result = await sendIvaRecs(data);

      setStep(STEPS.sending);

      pollState();

      return result;
    } catch (error) {
      DetailedMessage.error('Erro a enviar ficheiro', error);
    } finally {
      setLoading(false);
    }
  };

  const pollState = async () => {
    // are we polling already ?
    if (pollTimer) {
      return;
    }

    const timer = setInterval(checkState, 10000);
    setPollTimer(timer);
  };

  const checkState = async () => {
    let response = await fetchSystemState();
    console.log('IVARec polled server: ', response);
    if (!(response && response?.ok && response?.data)) {
      return;
    }
    // if so, get the results
    if ([STATES.sent, STATES.error].includes(response?.data?.currentState)) {
      console.log(response);

      setIvaRecResult(response?.data ?? []);
      setStep(STEPS.sent);
      if (!pollTimer) {
        return;
      }
      console.log('IVARec stopped polling server state');
      clearInterval(pollTimer);
      setPollTimer(null);
    }

    if ([STATES.sending].includes(response?.data?.currentState)) {
      setStep(STEPS.sending);
    }

    if ([STATES.idle].includes(response?.data?.currentState)) {
      clearInterval(pollTimer);
      setPollTimer(null);
      setStep(STEPS.idle);
    }
  };

  const downloadReport = async () => {
    await exportIvaRec(ivaRecResult.result, ivaRecResult.report);
  };

  const downloadDucZip = async zipBase64 => {
    const byteString = window.atob(zipBase64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);

    var downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'Vicki IVARec Delivery DUCs.zip';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const resetIvaRecSS = async () => {
    try {
      const res = await reset();

      if (!res?.ok) {
        throw new Error('Erro a limpar estado IVARec!');
      }

      setIvaRecResult(null);
      setConfirmReset(false);
      setFiles(null);
      setLoading(false);
      setStep(STEPS.idle);
    } catch (error) {
      message.error(error.message);
      console.error('Erro!', error);
    }
  };

  const StepIdle = () => {
    return (
      <div className="h-full w-full flex flex-col justify-center items-center">
        <div className="w-1/2 text-lg flex items-center justify-start pb-2">
          Declarações Recapitulativas de IVA
        </div>
        <div className="w-1/2 flex items-center justify-start pb-4">
          <div className="w-auto h-auto flex justify-center pr-4 rounded-lg">
            <div className="cursor-pointer overflow-hidden relative w-auto group">
              <button
                disabled={loading}
                onClick={() => {
                  if (!canClear) {
                    return;
                  }
                  setFiles(null);
                }}
                className={`cursor-pointer bg-white border border-blue-600 rounded-lg text-blue-600 group-hover:bg-blue-600 group-hover:text-white transition font-medium flex justify-center py-2 px-4 items-center`}
              >
                {canUpload ? (
                  <div className="cursor-pointer flex justify-center items-center">
                    Carregar declarações (.xml, aceita múltiplos)
                  </div>
                ) : (
                  <div className="cursor-pointer flex justify-center items-center">
                    <XMarkIcon className="w-5 h-5 stroke-current stroke-1"></XMarkIcon>
                    Cancelar
                  </div>
                )}
              </button>

              {canUpload && !loading && (
                <input
                  multiple
                  accept="text/xml"
                  className="cursor-pointer absolute block w-full h-full left-0 top-0 opacity-0 "
                  type="file"
                  name="finpartnerUploadIvaRecSS"
                  onChange={e => {
                    readFiles(e);
                  }}
                />
              )}
            </div>
          </div>

          <button
            onClick={doSend}
            disabled={!canDeliver || loading}
            className={`rounded-lg whitespace-normal break-normal transition border font-medium px-4 py-2 ${
              canDeliver
                ? 'border-blue-600 bg-blue-600 text-white hover:bg-blue-500 hover:border-blue-500'
                : 'bg-gray-200 border-gray-400 text-gray-600 cursor-not-allowed'
            } `}
          >
            Enviar Declarações
          </button>
        </div>
        <div
          className={`flex flex-col w-1/2 h-auto px-2 py-2 items-start justify-start text-gray-600 rounded-lg bg-gray-200 min-h-16`}
        >
          <div className="w-full text-md border-b border-gray-300">
            {files?.length ? `Ficheiros` : 'Lista de declarações (.xml)'}
          </div>
          <div className="w-full font-light text-sm flex flex-col items-start justify-start flex-wrap">
            {!!files?.length &&
              files.map((file, index) => {
                return <div key={file.name + '' + index}>{file.name}</div>;
              })}
          </div>
        </div>
      </div>
    );
  };

  const StepSending = () => {
    return (
      <div className="h-full w-full flex flex-col justify-center items-center">
        <div className="w-1/2 bg-gray-200 animate-pulse rounded-xl flex items-center justify-center py-8 px-8">
          A enviar declarações recapitulativas. Por favor aguarde, este processo
          poderá demorar vários minutos.
        </div>
      </div>
    );
  };

  const StepReport = () => {
    return (
      <div className="w-2/3 bg-gray-200 rounded-xl flex flex-col items-start justify-start my-4 py-8 px-8">
        <div className="w-full flex items-center justify-between pb-2 border-b border-gray-300">
          <div className="w-full text-md ">Resultado da entrega IVA Rec.</div>
          <button
            onClick={() => {
              console.log('click');
              setConfirmReset(true);
            }}
            disabled={isConfirmReset || loading}
            className={`rounded-lg whitespace-normal break-normal transition border-2 font-medium px-4 py-2 ${
              !isConfirmReset
                ? 'border-red-600 bg-red-600 text-white hover:bg-red-500 hover:border-red-500'
                : 'bg-transparent border-gray-600 text-gray-600 cursor-not-allowed'
            } `}
          >
            Reset
          </button>
        </div>

        {isConfirmReset ? (
          <div className="flex flex-col items-start justify-center py-4">
            <div className="mb-4 flex items-center text-center justify-center text-lg text-red-600">
              Ao limpar o estado da entrega, o relatório e quaisquer dados da
              entrega serão perdidos. Confirme esta acção:
            </div>
            <button
              onClick={resetIvaRecSS}
              disabled={loading}
              className={`rounded-lg mb-4 whitespace-normal break-normal transition border font-medium px-4 py-2 border-red-600 bg-red-600 text-white hover:bg-red-500 hover:border-red-500`}
            >
              Confirmar reset
            </button>
            <button
              onClick={() => setConfirmReset(false)}
              disabled={loading}
              className={`rounded-lg whitespace-normal break-normal transition border font-medium px-4 py-2 bg-white text-gray-800 hover:bg-gray-800 hover:text-white`}
            >
              Cancelar
            </button>
          </div>
        ) : (
          <div className="w-full flex flex-col items-start justify-center py-4">
            <div className="w-full flex items-center justify-between mb-4">
              <div className="text-2xl text-left w-3/5">
                Importação - Declarações Recapitulativas de IVA
              </div>
              <div className="w-full flex items-center justify-end">
                {ivaRecResult?.ducZipPath && (
                  <button
                    style={{
                      borderStyle: 'solid',
                      borderWidth: '1px !important'
                    }}
                    className="w-auto px-2 py-2 text-sm text-sky-500 mr-2 rounded-md border border-sky-600 bg-white hover:bg-sky-600 hover:text-white"
                    onClick={() => downloadDucZip(ivaRecResult?.ducZipPath)}
                  >
                    Guardar comprovativos
                  </button>
                )}
                {ivaRecResult?.report?.length && (
                  <button
                    style={{
                      borderStyle: 'solid',
                      borderWidth: '1px !important'
                    }}
                    className="w-auto px-2 py-2 text-sm text-sky-500 mr-2 rounded-md border border-sky-600 bg-white hover:bg-sky-600 hover:text-white"
                    onClick={() => {
                      exportIvaRec(ivaRecResult);
                    }}
                  >
                    Exportar para Excel
                  </button>
                )}
              </div>
            </div>
            <div className=" w-full h-auto flex flex-col items-start justify-start border-t min-h-16 bg-gray-100 text-sm">
              <div
                className={`w-full flex justify-start items-stretch border-r border-b `}
              >
                <div className="w-1/8 flex justify-start items-center break-all px-2 border-l">
                  NIF
                </div>
                <div className="w-1/8 flex justify-start items-center break-all px-2 border-l">
                  TOC
                </div>
                <div className="w-1/8 flex justify-start items-center px-2 border-l">
                  estado
                </div>
                <div className="w-2/8 flex justify-start items-center px-2 border-l">
                  ficheiro
                </div>
                <div className="w-3/8 flex justify-start items-center px-2 border-l">
                  erros
                </div>
              </div>
              {ivaRecResult?.report?.map((item, index) => {
                // const example = {
                //   received_date: Date,
                //   service_year: Date,
                //   value_received: Number,
                //   observation: String,
                //   clientName: String,
                //   clientNif: String,
                //   isUpdate: Boolean
                // };
                const isEven = index % 2 === 0;
                const isError = item.status !== 'Sucesso';
                return (
                  <div
                    key={index}
                    className={`w-full flex justify-start items-stretch border-r border-b ${
                      isError ? 'bg-red-50' : isEven ? 'bg-white' : 'bg-gray-50'
                    }`}
                  >
                    <div className="w-1/8 flex justify-start items-center break-all px-2 border-l">
                      <div className="table">{item.nif}</div>
                    </div>
                    <div className="w-1/8 flex justify-start items-center break-normal px-2 border-l">
                      <div className="table">{item.toc}</div>
                    </div>
                    <div className="w-1/8 flex justify-start items-center break-normal px-2 border-l">
                      <div className="table">{item.status}</div>
                    </div>
                    <div className="w-2/8 flex justify-start items-center break-all px-2 border-l">
                      <div className="table">{item.file?.originalname}</div>
                    </div>
                    <div className="w-3/8 flex justify-start items-center break-all px-2 border-l">
                      <div className="flex flex-col">
                        {item.errors.map(e => {
                          return <span>{e}</span>;
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {/* <div className="w-full font-light text-sm flex flex-col items-start justify-start flex-wrap">
          {!!ivaRecResult?.length &&
            ivaRecResult.map((ivaRec, index) => {
              return (
                <div
                  className="w-full flex items-center justify-start border-b border-gray-300"
                  key={ivaRec.name + '' + index}
                >
                  <div className="w-1/3 flex items-center justify-start border-r border-gray-300 py-1 px-2">
                    {ivaRec.name}
                  </div>
                  <div className="w-1/6 flex items-center justify-start border-r border-gray-300 py-1 px-2">
                    {ivaRec.status}
                  </div>
                  <div className="w-1/2 flex items-center justify-start px-2 py-1">
                    {ivaRec.message}
                  </div>
                </div>
              );
            })}
        </div> */}
      </div>
    );
  };

  return (
    <div className="flex-1 w-full flex flex-col justify-start items-center">
      {step === STEPS.idle && <StepIdle></StepIdle>}
      {step === STEPS.sending && <StepSending></StepSending>}
      {step === STEPS.sent && <StepReport></StepReport>}
      {step === STEPS.error && <div className="text-lg ">Erro!</div>}
    </div>
  );
};

const downloadDucZip = async zipBase64 => {
  const byteString = window.atob(zipBase64);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'application/zip' });
  const url = URL.createObjectURL(blob);

  var downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = 'Vicki DMRSS Delivery DUCs.zip';

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export default Home;
